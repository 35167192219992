@font-face {
  font-family: Roboto;
  font-weight: 300;
  src: url(/assets/Roboto-Light.ttf);
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  src: url(/assets/Roboto-Regular.ttf);
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  margin: 0;
  background-color: #f7f7f7;
}

h1 {
  font-weight: normal;
}

p {
  line-height: 1.5;
}

@media screen and (min-width: 750px) {
  .wrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
  .description,
  .image__wrapper {
    width: 50%;
  }

  .content {
    max-width: 800px;
    margin: 0 auto;
  }

  .content--landing {
    display: flex;
    flex-wrap: wrap;
  }
}

.content {
  padding: 50px;
}

.image {
  width: 100%;
}

.description {
  text-align: left;
}

.newsletter__container {
  margin-top: 30px;
}

.newsletter {
  margin: -10px -20px;
  padding: 10px 20px;
  background: #f2f2fd;
  position: relative;
  border-radius: 15px;
  font-size: 13px;
}
.legal {
  width: 100%;
  text-align: right;
}
.legal__link {
  padding: 10px;
}
.legal--app {
  margin-top: 80px;
}
.url-input__label {
  display: block;
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.url-input__wrapper {
  width: 100%;
  display: flex;
  border-radius: 10px;
}
.url-input {
  display: block;
  flex-grow: 1;
  padding: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.url-input:focus {
  outline: none;
}
.url-input__button {
  background: #a76b76;
  color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}
.url-input,
.url-input__button {
  border: 0;
  padding: 10px 15px;
  font-size: 1rem;
}
.url-input::placeholder {
  font-family: "Roboto", sans-serif;
}
.looping-button {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  display: block;
  width: 100%;
  margin: 1em 0;
  padding: 10px 15px;
  border: 0;
  border-radius: 5px;
  background: #a76b76;
  color: #fff;
  cursor: pointer;
}

.call-to-action {
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 2;
}

.call-to-action__link {
  display: inline-block;
  padding: 15px 20px;
  border-radius: 0 0 10px 10px;
  color: white;
  background: #a76b76;
  font-size: 1.1rem;
  text-align: left;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

/*
  Animate.css
 */
.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes shakeX {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shakeX {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.animate__shakeX {
  -webkit-animation-name: shakeX;
  animation-name: shakeX;
}
